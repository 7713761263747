'use client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as Icon from '@/ui/Icon';

import Form, { ErrorMessage } from '@/components/Form';
import Input from '@/components/Input';
import Button from '@/ui/Button';

import * as Styled from './styled';
import { Row, Stack } from '@/ui/primatives';
import { useState } from 'react';
import { GithubOauthProvider } from '../OauthProviders/Github';
import { GoogleOauthProvider } from '../OauthProviders/Google';
import MagicLink from './MagicLink';
import { GIT_HUB_CLIENT_ID } from '@/config/environment';

export type FormValues = {
  identifier: string;
};

interface LoginFormProps {
  onSubmit: Function;
  redirect: string;
  loginError?: string;
}

const SectionBreak: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Row gap={10} alignItems="center">
    <Styled.Seperator />
    {children}
    <Styled.Seperator />
  </Row>
);

const LoginErrorDisplay: React.FC<{ error: string }> = ({ error }) => {
  const errorMessage =
    error === 'failed-oauth'
      ? "We couldn't identify you with this method, please try again."
      : error === 'invalid-token'
        ? 'The link you used is expired or has already been used. Please request another one.'
        : error;
  return <ErrorMessage>{errorMessage}</ErrorMessage>;
};

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, redirect, loginError }) => {
  const [error, setError] = useState(loginError);
  const submitLogin = (params: { identifier: string }) => {
    const { identifier } = params;
    setError('');
    onSubmit({ identifier });
  };

  const formSchema = Yup.object().shape({
    identifier: Yup.string().required('Required'),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
  });

  const identifierWatch = watch('identifier');

  return (
    <Form onSubmit={handleSubmit((v) => submitLogin(v))}>
      <Stack gap={40}>
        <Stack gap={20}>
          <Input
            name="identifier"
            label="Username or email address"
            error={errors?.identifier}
            submit={register}
            style={{ height: 36 }}
          ></Input>

          <Button.Black stretch testId="register-submit">
            <span>Use your password</span>
            <Icon.ArrowRight />
          </Button.Black>
          <MagicLink identifier={identifierWatch} redirect={redirect} />
        </Stack>

        {GIT_HUB_CLIENT_ID && (
          <>
            <SectionBreak>OR</SectionBreak>
            <GithubOauthProvider />
          </>
        )}

        {error && <LoginErrorDisplay error={error} />}
      </Stack>
    </Form>
  );
};

export default LoginForm;
