'use client';

import * as Icon from '@/ui/Icon';
import { sendMagicLink } from '@/lib/usersApi';
import { ErrorMessage, SuccessMessage } from '@/components/Form';
import Button from '@/ui/Button';

import { useEffect, useState } from 'react';

const MagicLinkButton: React.FC<{ onClick: Function; disabled: boolean }> = ({ onClick, disabled }) => {
  return (
    <Button.Basic stretch onClick={(e) => onClick(e)} disabled={disabled}>
      <Icon.Envelope />
      Send a magic link
    </Button.Basic>
  );
};

interface MagicLinkProps {
  identifier: string;
  redirect: string;
  error?: string;
}

export const onSendMagicLink = async (
  identifier: string,
  redirect: string,
  setErrorMessage: (message: string) => void,
  setSuccessMessage: (message: string) => void,
) => {
  if (!identifier) {
    setErrorMessage('Please enter an email or username to send the magic link to.');
    return;
  }
  const serverResponse = await sendMagicLink(identifier, redirect);
  if (serverResponse?.error?.title) {
    setErrorMessage(serverResponse.error.title);
    return;
  }
  if (serverResponse?.status === 'success') {
    setSuccessMessage('If you have an Oxen account, we just sent you a magic link!');
  } else {
    setErrorMessage(serverResponse?.error?.title || 'An error occurred while sending the magic link.');
  }
};

const MagicLink: React.FC<MagicLinkProps> = ({ identifier, redirect }) => {
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    setErrorMessage(undefined);
    setMagicLinkSent(false);
  }, [identifier]);

  const doSendMagicLink = async (e: MouseEvent) => {
    onSendMagicLink(identifier, redirect, setErrorMessage, setSuccessMessage);
    setMagicLinkSent(true);
    e.stopPropagation();
  };

  return (
    <>
      <MagicLinkButton onClick={doSendMagicLink} disabled={magicLinkSent && !errorMessage} />

      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default MagicLink;
