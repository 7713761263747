'use client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as Icon from '@/ui/Icon';
import Form, { FieldSet, ErrorMessage, SuccessMessage } from '@/components/Form';
import Input from '@/components/Input';
import Button from '@/ui/Button';
import { onSendMagicLink } from './MagicLink';

import Link from 'next/link';
import { useEffect, useState } from 'react';
import { Stack } from '@/ui/primatives';

export type FormValues = {
  identifier: string;
  password: string;
};

interface LoginDetailsFormProps {
  responseError?: any;
  onSubmit: Function;
  defaultValues: FormValues;
  redirect: string;
  isLoading?: boolean;
}

const LoginDetailsForm: React.FC<LoginDetailsFormProps> = ({
  responseError,
  onSubmit,
  defaultValues,
  redirect,
  isLoading,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(responseError?.title);
  const [errorType, setErrorType] = useState<string | undefined>(responseError?.type);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const submitLogin = (params: { identifier: string; password: string }) => {
    const { identifier, password } = params;
    onSubmit({ identifier, password });
  };

  const formSchema = Yup.object().shape({
    identifier: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: defaultValues,
  });

  const identifierWatch = watch('identifier');
  const passwordWatch = watch('password');

  const clearErrors = () => {
    setErrorType(undefined);
    setErrorMessage(undefined);
  };

  useEffect(() => {
    setErrorType(responseError?.type);
    setErrorMessage(responseError?.title);
  }, [responseError]);

  // Clear error state when password changes
  useEffect(() => {
    clearErrors();
  }, [passwordWatch]);

  const sendMagicLink = async (e?: MouseEvent) => {
    clearErrors();
    onSendMagicLink(identifierWatch, redirect, setErrorMessage, setSuccessMessage);
  };

  return (
    <Form onSubmit={handleSubmit((v) => submitLogin(v))}>
      <FieldSet>
        <Input name="identifier" label="Username or email address" error={errors?.identifier} submit={register}></Input>
        <Stack gap={10}>
          <Input
            name="password"
            type="password"
            label="Password"
            wide
            error={errors?.password}
            submit={register}
          ></Input>
          <Link className="link" href={'/login/forgot-password'}>
            Forgot Password?
          </Link>
        </Stack>
      </FieldSet>

      <Button stretch testId="register-submit" variant="primary" disabled={isLoading}>
        <span>LOG IN</span>
        <Icon.ArrowRight />
      </Button>

      {errorMessage && (
        <ErrorMessage>
          {errorMessage}
          {errorType === 'invalid-token' && (
            <div
              style={{ paddingTop: '5px', cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => sendMagicLink()}
            >
              Or click here to try logging in with a magic link.
            </div>
          )}
        </ErrorMessage>
      )}

      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
    </Form>
  );
};

export default LoginDetailsForm;
